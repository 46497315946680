<template>
	<div class="recom-list">
		<div class="li" v-for="goods,index in goodsList" :key="index">
			<img class="pic" :src="goods.default_image" alt="">
			<div class="li-main">
				<div class="name">{{goods.goods_name}}</div>
				<div class="price"><span>￥<em>{{goods.price}}</em></span> {{goods.sales?goods.sales:0}}人付款</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { fetchList } from '@/api/seller/goods';
	export default {
		data() {
			return {
				goodsList: []
			}
		},
		props: ['value'],
		created() {
			this.getRecommendGoods();
		},
		methods:{
			getRecommendGoods(){
				let params = {
					goods_ids: this.value,
					page_index: 0,
					page_size: 4
				}
				fetchList(params).then(response => {
					this.goodsList = response.data.list;
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.recom-list {
		padding: 10px;
		.li {
			display: flex;
			margin-bottom: 10px;
			background-color: #FFFFFF;
			border-radius: 10px;
			overflow: hidden;
			.pic {
				width: 140px;
				height: 140px;
				object-fit: cover;
			}
			.li-main {
				padding: 8px 10px;
				.name {
					height: 40px;
					line-height: 20px;
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}
				.price {
					color: #999;
					height: 24px;
					line-height: 24px;
					margin-top: 60px;
					font-size: 13px;
					span {
						color: rgb(255, 80, 0);
					}
					em {
						font-size: 18px;
					}
				}
			}
		}
		.li:last-child {
			margin-bottom: 0;
		}
	}
</style>
