import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 店铺装修
export function listFolder(data) {
  return request({
    url: '/seller/metarial/listFolder',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createFolder(data) {
  return request({
    url: '/seller/metarial/createFolder',
    method: 'post',
    data: param2Obj(data)
  })
}

export function renameFolder(data) {
  return request({
    url: '/seller/metarial/remane',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchMetarial(data) {
  return request({
    url: '/seller/metarial/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateMetarialStatus(data) {
  return request({
    url: '/seller/metarial/updateStatus',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteMetarial(data) {
  return request({
    url: '/seller/metarial/move',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchMobile(data) {
  return request({
    url: '/seller/diy/mobile/page/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function moduleList(data) {
  return request({
    url: '/seller/diy/page/module/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchModule(data) {
  return request({
    url: '/seller/diy/page/module/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createModule(data) {
  return request({
    url: '/seller/diy/page/module/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateModule(data) {
  return request({
    url: '/seller/diy/page/module/edit',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateMobile(data) {
  return request({
    url: '/seller/diy/mobile/page/edit',
    method: 'post',
    data: param2Obj(data)
  })
}

export function releaseMobile(data) {
  return request({
    url: '/seller/diy/mobile/page/release',
    method: 'post',
    data: param2Obj(data)
  })
}

export function mobileGoods(data) {
  return request({
    url: '/seller/diy/mobile/page/goods',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateStoreLogo(data) {
  return request({
    url: '/seller/store/setLogo',
    method: 'post',
    data: param2Obj(data)
  })
}

