import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 商品
export function fetchList(data) {
  return request({
    url: '/seller/goods/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function goodsUpdates(data) {
  return request({
    url: '/seller/goods/isShow',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchGoods(data) {
  return request({
    url: '/seller/goods/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createGoods(data) {
  return request({
    url: '/seller/goods/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateGoods(data) {
  return request({
    url: '/seller/goods/update',
    method: 'post',
    data: param2Obj(data)
  })
}

export function goodsDelete(data) {
  return request({
    url: '/seller/goods/batchDelete',
    method: 'post',
    data: param2Obj(data)
  })
}
