<template>
	<div class="wp-editor">
		<div class="uu-header">
			<div class="header-wrap">
				<a class="logo"><img src="@/assets/images/seller/decorate-logo-black.png" alt=""></a>
				<span>{{$t('seller.pageEditor.pagecurr')}}{{decorateInfo.name}}</span>
			</div>
			<ul class="action">
				<li>
					<el-button type="primary" size="mini" @click="handlePublish">{{$t('seller.actions.publish')}}</el-button>
				</li>
			</ul>
		</div>
		<div class="uu-content">
			<div class="side-menu"></div>
			<div class="content-wrap">
				<div class="module-drawer">
					<div class="module-drawer-head">
						<h2>{{$t('seller.pageEditor.drawerTitle')}}</h2>
						<div class="text"><span>{{$t('seller.pageEditor.drawerTitle')}}</span><i class="el-icon-question"></i></div>
						<!-- <el-input v-model="keyword" size="medium">
							<div class="btn--append" slot="append">
								<i class="el-icon-search"></i>
							</div>
						</el-input> -->
					</div>
					<!-- <div class="module-drawer-tabs">
						<ul>
							<li class="active">官方模块</li>
							<li>已购小程序模块</li>
						</ul>
					</div> -->
					<div class="module-drawer-main">
						<el-scrollbar>
							<el-collapse v-model="moduleActive" @change="handleModuleChange">
								<template v-for="item,index in moduleList">
									<el-collapse-item :key="index" v-if="item.children.length>0" :title="item.name+'('+item.children.length+')'" :name="item.id">
										<draggable
											class="module-list"
											v-model="moduleList[index].children"
											:options="{group:{name:'previewer',pull:'clone'},sort:true}"
											handle=".item-pic"
											@end="onDraggableEnd"
											:move="onDraggableMove">
											<div class="item" v-for="child,cindex in item.children" :key="cindex">
												<div class="item-basket gczpge"><span>{{$t('seller.pageEditor.basket')}}</span></div>
												<div class="item-pic"><img :src="child.image" alt=""></div>
												<div class="item-title">{{child.name}}</div>
												<div class="item-num">{{getItemText(child)}}</div>
											</div>
										</draggable>
									</el-collapse-item>
								</template>
							</el-collapse>
						</el-scrollbar>
					</div>
				</div>
				<div class="previewer">
					<el-scrollbar>
						<div class="previewer-main">
							<div class="previewer-head">
								<div class="name"><span>{{userInfo.userName}}</span><i class="el-icon-arrow-right"></i></div>
							</div>
							<draggable
								class="previewer-list"
								v-model="previewerList"
								handle=".item-main"
								group="previewer"
								:move="onDraggableMove2">
								<div class="item" v-for="item,index in previewerList" :key="index">
									<div class="item-main">
										<div class="item-title">{{item.name}}</div>
										<template v-if="item.belong_container=='text'">
											<div class="item-notice">
												<img class="icon-notice" src="@/assets/images/seller/decorate-steps-136-136.png" >
												<span>{{moduleForm.content.attr_data&&panelIndex==index&&panelForm.belong_container=='text'?moduleForm.content.attr_data:item.content}}</span>
												<i class="el-icon-arrow-right"></i>
											</div>
										</template>
										<template v-else-if="item.belong_container=='video-single'">
											<div class="item-pic">
												<video v-if="item.content" :src="item.content"></video>
												<img src="@/assets/images/seller/decorate-steps-750-376.png" v-else>
											</div>
										</template>
										<template v-else-if="item.belong_container=='goods-ranking'">
											<div class="item-ranking">
												<div class="rank-tabs">
													<div class="li active">{{$t('seller.pageEditor.salesList')}}</div>
													<div class="li">{{$t('seller.pageEditor.collectList')}}</div>
													<div class="li">{{$t('seller.pageEditor.newList')}}</div>
												</div>
												<div class="rank-list">
													<div class="li" v-for="goods,index2 in rankGoodsList.slice(0,3)" :key="index2">
														<img class="pic" :src="goods.default_image" @error="imageGoodsError" alt="">
														<div class="li-main">
															<img class="icon-top" v-if="index2 == 0" src="@/assets/images/seller/decorate-steps-136-160-1.png" alt="">
															<img class="icon-top" v-if="index2 == 1" src="@/assets/images/seller/decorate-steps-136-160-2.png" alt="">
															<img class="icon-top" v-if="index2 == 2" src="@/assets/images/seller/decorate-steps-136-160-3.png" alt="">
															<div class="name">{{goods.goods_name}}</div>
															<div class="text"><span><i class="icon-hot"></i> 0</span>{{$t('seller.pageEditor.interestNum')}}</div>
															<div class="price"><span>￥<em>{{goods.price}}</em></span> {{goods.sales?goods.sales:0}}{{$t('seller.pageEditor.salesNum')}}</div>
														</div>
													</div>
													<div class="li-more">{{$t('seller.actions.viewAll')}}</div>
												</div>
											</div>
										</template>
										<template v-else-if="item.belong_container=='goods-recommend'">
											<div class="item-recommend" v-if="panelIndex==index && goodsList.length>0 && parseInt(item.style_type)==1">
												<div class="recom-list">
													<div class="li" v-for="goods,index2 in goodsList" :key="index2">
														<img class="pic" :src="goods.default_image" @error="imageGoodsError" alt="">
														<div class="li-main">
															<div class="name">{{goods.goods_name}}</div>
															<div class="price"><span>￥<em>{{goods.price}}</em></span> {{goods.sales?goods.sales:0}}{{$t('seller.pageEditor.salesNum')}}</div>
														</div>
													</div>
												</div>
											</div>
											<div class="item-recommend" v-else-if="item.page_module_id>0 && parseInt(item.style_type)==1 && item.content">
												<decorate-recom v-model="item.content"></decorate-recom>
											</div>
											<div class="item-pic" v-else>
												<img v-if="parseInt(item.style_type)==3" src="@/assets/images/seller/decorate-steps-375-345.png" alt="">
												<img v-else-if="parseInt(item.style_type)==2" src="@/assets/images/seller/decorate-steps-375-505.png" alt="">
												<img v-else src="@/assets/images/seller/decorate-steps-375-620.png" alt="">
											</div>
										</template>
										<template v-else-if="item.belong_container=='like'">
											<div class="item-list">
												<div class="item-list-head"><img src="@/assets/images/seller/decorate-steps-782-70.png" alt=""></div>
												<div class="item-list-main">
													<div class="item-goods" v-for="goods,index in moreGoodsList" :key="index">
														<img class="pic" :src="goods.default_image" @error="imageGoodsError" alt="">
														<div class="name">{{goods.goods_name}}</div>
														<div class="text">
															<span class="price">￥<em>{{goods.price}}</em></span>
															<span class="sales" v-if="goods.sales">{{parseInt(goods.sales)>999?'999+':goods.sales}}{{$t('seller.pageEditor.salesNum')}}</span>
															<span class="sales" v-else>0{{$t('seller.pageEditor.salesNum')}}</span>
														</div>
													</div>
												</div>
											</div>
										</template>
										<template v-else>
											<div class="item-pic">
												<img v-if="item.content" :src="item.content" @error="imageLoadError">
												<img src="@/assets/images/seller/decorate-steps-750-376.png" v-else>
											</div>
										</template>
									</div>
									<div :class="[panelIndex==index?'active':'','item-overlay']" @click="handlePreviewerChange(item,index)"></div>
									<div class="item-action" v-show="panelIndex==index">
										<div class="li" :class="[panelIndex==index?'active':'']"><i class="el-icon-s-tools" @click="handlePreviewerChange()"></i></div>
										<div class="li disabled" v-if="item.belong_container=='like'">
											<i class="el-icon-top"></i>
											<i class="el-icon-bottom"></i>
										</div>
										<div class="li" v-else>
											<i class="el-icon-top" :class="[index==0?'disabled':'']" @click="handlePreviewerUp(index)"></i>
											<i class="el-icon-bottom" :class="[index==previewerList.length-1?'disabled':'']" @click="handlePreviewerDown(index)"></i>
										</div>
										<div class="li disabled" v-if="item.belong_container=='like'"><i class="el-icon-delete-solid"></i></div>
										<div class="li" @click="handlePreviewerRemove(index)" v-else><i class="el-icon-delete-solid"></i></div>
									</div>
								</div>
							</draggable>
						</div>
					</el-scrollbar>
				</div>
				<div class="layout-manager">
					<div class="layout-manager-title">
						<span>{{$t('seller.pageEditor.layoutTitle')}}</span>
						<span style="flex: 1;"></span>
						<!-- <i class="el-icon-close"></i> -->
					</div>
					<div class="layout-manager-list">
						<el-scrollbar>
							<draggable v-model="previewerList" @end="onDraggableEnd2" handle=".icon1" style="padding: 0 12px;">
								<div class="item"
									v-for="item,index in previewerList"
									:key="index"
									:class="[panelIndex==index?'active':'']"
									@click="handlePreviewerChange(item,index)">
									<i class="item-icon icon2" v-if="item.belong_container=='like'"></i>
									<i class="item-icon icon1" v-else></i>
									<span>{{item.name}}</span>
								</div>
							</draggable>
						</el-scrollbar>
					</div>
				</div>
				<div class="editor-panel" v-if="panelForm">
					<div class="editor-panel-title">
						<span class="name">{{panelForm.name}}</span>
						<div class="uu-flex"></div>
						<a class="next-btn" href="javascript:void(0);" @click="handleShowModule" v-if="panelForm.belong_container!=='like'">{{$t('seller.actions.selectModule')}}<i class="el-icon-arrow-right"></i></a>
					</div>
					<div class="editor-panel-main">
						<el-scrollbar>
							<el-collapse v-model="panelActive" @change="handlePanelChange">
								<el-collapse-item :title="$t('seller.pageEditor.panelTitle1')" name="1">
									<el-form ref="moduleForm" :model="moduleForm" label-position="top" size="mini">
										<!-- 轮播图海报 -->
										<template v-if="panelForm.belong_container==='carousel'">
											<el-form-item :label="$t('seller.formItem.moduleName')" prop="name" :rules="{required:true,message: $t('seller.validator.moduleName'),trigger:'blur'}">
												<el-input v-model="moduleForm.name" :placeholder="$t('seller.placeholder.moduleName')"></el-input>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.basicSetup')" prop="content.attr_type" :rules="{required:true,message: $t('seller.validator.basicSetup'),trigger:'change'}">
												<el-radio-group v-model="moduleForm.content.attr_type">
													<el-radio v-for="(item,index) in basicSetupOption" :key="index" :label="item.value">{{item.label}}</el-radio>
												</el-radio-group>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.uploadPic')" prop="content.attr_thumb" :rules="{required:true,validator: validateThumb,trigger:'blur'}">
												<p class="notice">{{$t('seller.pageEditor.wpTips1')}}</p>
												<div class="thumb-list">
													<div class="item" v-for="item,index in thumbList" :key="index">
														<div class="item-move">
															<i class="el-icon-top" @click="handleUpImage(index)" :class="[index==0?'disabled':'']"></i>
															<i class="el-icon-bottom" @click="handleDownImage(index)" :class="[index==(thumbList.length-1)?'disabled':'']"></i>
														</div>
														<div class="item-con">
															<div class="item-wrap">
																<img v-if="item.img_url" @click="handleUploadOpen('thumbs',index)" :src="item.img_url" alt="">
																<i class="el-icon-plus" @click="handleUploadOpen('thumbs',index)" v-else></i>
															</div>
															<div class="item-wrap"><el-input v-model="item.link" :placeholder="$t('seller.placeholder.wirelessLink')"></el-input></div>
														</div>
														<div class="item-del">
															<i class="el-icon-delete-solid" @click="handleRemoveImage(index)" v-if="thumbList.length>1"></i>
															<i class="el-icon-delete-solid disabled" v-else></i>
														</div>
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<div class="btn-add" @click="handleAddImage"><i class="el-icon-plus"></i> {{$t('seller.actions.add')}}{{thumbList.length}}/4</div>
											</el-form-item>
										</template>
										<!-- 单图海报 -->
										<template v-else-if="panelForm.belong_container==='poster-single'">
											<el-form-item :label="$t('seller.formItem.moduleName')" prop="name" :rules="{required:true,message: $t('seller.validator.moduleName'),trigger:'blur'}">
												<el-input v-model="moduleForm.name" :placeholder="$t('seller.placeholder.moduleName')"></el-input>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.uploadPic')" prop="content.attr_data" :rules="{required:true,message: $t('seller.validator.uploadPic'),trigger:'blur'}">
												<p class="notice">{{$t('seller.pageEditor.wpTips2')}}</p>
												<div class="btn-thumb" @click="handleUploadOpen('poster')">
													<template v-if="moduleForm.content.attr_data"><img :src="moduleForm.content.attr_data" alt=""></template>
													<template v-else><i class="el-icon-plus"></i>{{$t('seller.actions.addImage')}}</template>
												</div>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.subUndertake')">
												<el-radio-group v-model="moduleForm.content.attr_type">
													<el-radio v-for="(item,index) in subUndertakeOption" :key="index" :label="item.value">{{item.label}}</el-radio>
												</el-radio-group>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.jumpLink')" prop="content.attr_link" :rules="{required:true,message: $t('seller.validator.link'),trigger:'blur'}">
												<el-input v-model="moduleForm.content.attr_link" :placeholder="$t('seller.placeholder.link')"></el-input>
											</el-form-item>
										</template>
										<!-- 文字标题 -->
										<template v-else-if="panelForm.belong_container==='text'">
											<el-form-item :label="$t('seller.formItem.moduleName')" prop="name" :rules="{required:true,message: $t('seller.validator.moduleName'),trigger:'blur'}">
												<el-input v-model="moduleForm.name" :placeholder="$t('seller.placeholder.moduleName')"></el-input>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.styleOption')">
												<el-radio-group class="style-list" v-model="moduleForm.content.attr_type">
													<el-radio label="1">
														<div class="pic"><img src="@/assets/images/seller/decorate-steps-375-53.png" alt=""></div>
														<div class="name">{{$t('seller.pageEditor.officialStyle')}}</div>
													</el-radio>
												</el-radio-group>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.title')" prop="content.attr_data" :rules="{required:true,message: $t('seller.validator.title'),trigger:'blur'}">
												<el-input v-model="moduleForm.content.attr_data" :placeholder="$t('seller.placeholder.title')" maxlength="20" show-word-limit></el-input>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.link')" prop="content.attr_link" :rules="{required:true,message: $t('seller.validator.link'),trigger:'blur'}">
												<el-input v-model="moduleForm.content.attr_link" :placeholder="$t('seller.placeholder.link')"></el-input>
											</el-form-item>
										</template>
										<!-- 单视频 -->
										<template v-else-if="panelForm.belong_container==='video-single'">
											<el-form-item :label="$t('seller.formItem.moduleName')" prop="name" :rules="{required:true,message: $t('seller.validator.moduleName'),trigger:'blur'}">
												<el-input v-model="moduleForm.name" :placeholder="$t('seller.placeholder.moduleName')"></el-input>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.vedioSize')">
												<el-radio-group v-model="moduleForm.content.attr_type">
													<el-radio label="1">16:9</el-radio>
													<el-radio label="2">3:4/9:16</el-radio>
												</el-radio-group>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.addVedio')">
												<el-upload
													action="#"
													v-loading="loadingUplaod"
													:show-file-list="false"
													class="btn-thumb"
													:http-request="httpRequestVideoUpload">
													<div class="btn-thumb-main">
														<template v-if="moduleForm.content.attr_data"><video :src="moduleForm.content.attr_data"></video></template>
														<template v-else><i class="el-icon-plus"></i>{{$t('seller.actions.addVideo')}}</template>
													</div>
												</el-upload>
												<p class="notice">{{$t('seller.pageEditor.wpTips3')}}</p>
												<p class="notice"><a href="javascript:void(0);">{{$t('seller.actions.viewStandard')}} <i class="el-icon-arrow-right"></i></a></p>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.vedioSubpage')">
												<el-radio-group v-model="moduleForm.content.attr_status">
													<el-radio v-for="(item,index) in vedioSubpageOption" :key="index" :label="item.value">{{item.label}})</el-radio>
												</el-radio-group>
												<template v-if="moduleForm.content.attr_status==2">
													<el-input v-model="moduleForm.content.attr_link" :placeholder="$t('seller.placeholder.wirelessLink')"></el-input>
												</template>
											</el-form-item>
										</template>
										<!-- 排行榜 -->
										<template v-else-if="panelForm.belong_container==='goods-ranking'">
											<el-form-item :label="$t('seller.formItem.moduleName')" prop="name" :rules="{required:true,message: $t('seller.validator.moduleName'),trigger:'blur'}">
												<el-input v-model="moduleForm.name" :placeholder="$t('seller.placeholder.moduleName')"></el-input>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.styleOption')">
												<el-radio-group class="style-list" v-model="moduleForm.content.attr_type">
													<el-radio label="1">
														<div class="pic"><img src="@/assets/images/seller/decorate-steps-375-497.png" alt=""></div>
														<div class="name">{{$t('seller.pageEditor.officialStyle')}}</div>
													</el-radio>
												</el-radio-group>
											</el-form-item>
											<el-form-item>
												<p class="notice">{{$t('seller.pageEditor.wpTips4')}}</p>
											</el-form-item>
											<el-empty :description="$t('seller.empty.autoEmerge')"></el-empty>
										</template>
										<!-- 智能宝贝推荐 -->
										<template v-else-if="panelForm.belong_container==='goods-recommend'">
											<el-form-item :label="$t('seller.formItem.moduleName')" prop="name" :rules="{required:true,message: $t('seller.validator.moduleName'),trigger:'blur'}">
												<el-input v-model="moduleForm.name" :placeholder="$t('seller.placeholder.moduleName')"></el-input>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.moduleStyle')">
												<el-radio-group class="style-list" v-model="moduleForm.content.attr_type" @change="handleRecommendChange">
													<el-radio label="1">
														<div class="pic"><img src="@/assets/images/seller/decorate-steps-375-744.png" alt=""></div>
														<div class="name">1{{$t('seller.pageEditor.row')}}1</div>
													</el-radio>
													<el-radio label="2">
														<div class="pic"><img src="@/assets/images/seller/decorate-steps-375-635.png" alt=""></div>
														<div class="name">1{{$t('seller.pageEditor.row')}}2</div>
													</el-radio>
													<el-radio label="3">
														<div class="pic"><img src="@/assets/images/seller/decorate-steps-375-536.png" alt=""></div>
														<div class="name">1{{$t('seller.pageEditor.row')}}3</div>
													</el-radio>
												</el-radio-group>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.bannerGraph')">
												<p class="notice">{{$t('seller.pageEditor.wpTips5')}}</p>
												<div class="btn-thumb" @click="handleUploadOpen('banner')">
													<template v-if="moduleForm.content.attr_banner"><img :src="moduleForm.content.attr_banner" alt=""></template>
													<template v-else><i class="el-icon-plus"></i>{{$t('seller.actions.addImage')}}</template>
												</div>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.bannerLink')">
												<el-input v-model="moduleForm.content.attr_link" :placeholder="$t('seller.placeholder.wirelessLink')"></el-input>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.goodsStock')">
												<el-radio-group v-model="moduleForm.content.attr_status">
													<el-radio v-for="(item,index) in goodsStockOption" :key="index" :label="item.value">{{item.label}}</el-radio>
												</el-radio-group>
											</el-form-item>
											<el-form-item prop="content.attr_data">
												<div class="goods-list" v-if="goodsList.length>0">
													<div class="item" v-for="item,index in goodsList.slice(0,4)" :key="index">
														<img :src="item.default_image" alt="">
														<i class="el-icon-more" v-if="goodsList.length-1 == index"></i>
													</div>
												</div>
												<div class="btn-add" @click="handleOpenGoods"><i class="el-icon-plus"></i> {{$t('seller.actions.addGoods')}}{{goodsList.length}}/50</div>
												<p class="notice mt10">
													<span>{{$t('seller.pageEditor.wpText1-1')}}{{goodsList.length}}{{$t('seller.pageEditor.wpText1-2')}}</span>
													<!-- <a class="right" href="javascript:void(0);" @click="handleOpenGoods">{{$t('seller.actions.viewGoods')}}</a> -->
												</p>
											</el-form-item>
										</template>
										<!-- 店铺优惠券 -->
										<template v-else-if="panelForm.belong_container==='coupon-shop'">
											<el-form-item :label="$t('seller.formItem.moduleName')" prop="name" :rules="{required:true,message: $t('seller.validator.moduleName'),trigger:'blur'}">
												<el-input v-model="moduleForm.name" :placeholder="$t('seller.placeholder.moduleName')"></el-input>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.styleOption')">
												<el-radio-group class="style-list" v-model="moduleForm.content.attr_type">
													<el-radio label="1">
														<div class="pic"><img src="@/assets/images/seller/decorate-steps-375-96.png" alt=""></div>
														<div class="name">{{$t('seller.pageEditor.officialStyle')}}</div>
													</el-radio>
												</el-radio-group>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.couponAdd')">
												<el-radio-group v-model="moduleForm.content.attr_status">
													<el-radio v-for="(item,index) in couponAddOption" :key="index" :label="item.value" :disabled="item.value==2">{{item.label}}</el-radio>
												</el-radio-group>
											</el-form-item>
											<el-form-item :label="$t('seller.formItem.couponSet')" prop="content.attr_data" :rules="{required:true,message: $t('seller.validator.complete'),trigger:'blur'}">
												<div class="mb12">
													<el-select v-model="moduleForm.content.attr_coupon" @change="handleCouponChange" style="width: 100%;">
														<el-option label="1" value="1"></el-option>
														<el-option label="2" value="2"></el-option>
														<el-option label="3" value="3"></el-option>
														<el-option label="4" value="4"></el-option>
														<el-option label="5" value="5"></el-option>
														<el-option label="6" value="6"></el-option>
													</el-select>
												</div>
												<div class="thumb-list">
													<div class="item" v-for="item,index in couponList" :key="index">
														<div class="item-move">
															<i class="el-icon-top" @click="handleUpCoupon(index)" :class="[index==0?'disabled':'']"></i>
															<i class="el-icon-bottom" @click="handleDownCoupon(index)" :class="[index==couponList.length-1?'disabled':'']"></i>
														</div>
														<div class="item-wrap">
															<img src="@/assets/images/seller/decorate-steps-137-74.png" >
															<span v-if="item.quota">{{$t('seller.pageEditor.wpText2-1')}}{{item.quota}}{{$t('seller.pageEditor.wpText2-1')}}</span>
															<span v-else>{{$t('seller.actions.selectCoupon')}}</span>
														</div>
														<div class="item-del">
															<i class="el-icon-s-ticket" @click="handleOpenCoupon(index,item)"></i>
														</div>
													</div>
												</div>
											</el-form-item>
											<el-form-item>
												<p class="notice"><a href="/home/market/coupon-create" target="_blank">{{$t('seller.actions.createMoreCoupon')}} <i class="el-icon-arrow-right"></i></a></p>
											</el-form-item>
										</template>
										<template v-else>
											<el-empty :description="$t('seller.empty.autoEmerge')"></el-empty>
										</template>
									</el-form>
								</el-collapse-item>
								<!-- <el-collapse-item title="智能展现设置" name="2">
									智能展现设置
								</el-collapse-item> -->
							</el-collapse>
						</el-scrollbar>
					</div>
					<div class="editor-panel-footer">
						<el-button type="primary" size="medium" @click="handleAddModule">{{$t('seller.actions.save')}}</el-button>
						<el-button size="medium" @click="handleModuleClose">{{$t('seller.actions.cancel')}}</el-button>
					</div>
					<el-dialog
						:title="$t('seller.dialogTitle.moduleName')+panelForm.name"
						:visible.sync="dialogVisibleModule"
						width="810px"
						class="dialog-module"
						:before-close="handleCancelModule">
						<el-scrollbar style="height: 520px;">
							<el-radio-group class="dialog-module-list" v-model="checkModule">
								<el-radio v-for="(item,index) in dialogModule" :key="index" :label="item.page_module_id">
									<div class="goods-item">
										<div class="item-main">
											<template v-if="item.belong_container=='carousel'">
												<img :src="item.content.attr_thumb?item.content.attr_thumb[0].img_url:''" alt="">
											</template>
											<template v-else-if="item.belong_container=='text'">
												<div class="item-notice">
													<img class="icon-notice" src="@/assets/images/seller/decorate-steps-136-136.png" >
													<span>{{item.attr_data}}</span>
													<i class="el-icon-arrow-right"></i>
												</div>
											</template>
											<template v-else-if="item.belong_container=='video-single'">
												<video v-if="item.content.attr_data" :src="item.content.attr_data"></video>
												<img src="@/assets/images/seller/decorate-steps-750-376.png" v-else>
											</template>
											<template v-else-if="item.belong_container=='coupon-shop'">
												<img src="@/assets/images/seller/decorate-steps-375-96.png">
											</template>
											<template v-else>
												<img v-if="item.content.attr_data" :src="item.content.attr_data" alt="">
												<img src="@/assets/images/seller/decorate-steps-750-376.png" v-else>
											</template>
										</div>
										<div class="item-box">
											<div class="name">{{item.name}}</div>
											<div class="text">{{panelForm.name}} | {{item.update_time | parseTime('{y}-{m}-{d}')}}</div>
										</div>
									</div>
								</el-radio>
							</el-radio-group>
						</el-scrollbar>
						<div class="uu-footer" v-if="listTotalModule>0">
							<el-pagination
								@size-change="handleSizeChangeModule"
								@current-change="handleCurrentChangeModule"
								:current-page.sync="pagecurrModule"
								:page-size="pagesizeModule"
								layout="total, prev, pager, next"
								:prev-text="$t('seller.pagination.prevText')"
								:next-text="$t('seller.pagination.nextText')"
								:total="listTotalModule">
							</el-pagination>
						</div>
						<div slot="footer">
							<el-button @click="handleCancelModule" size="medium">{{$t('seller.actions.cancel')}}</el-button>
							<el-button type="primary" @click="handleConfirmModule" size="medium">{{$t('seller.actions.confirm')}}</el-button>
						</div>
					</el-dialog>
					<el-dialog
						:title="$t('seller.dialogTitle.image')"
						class="dialog-upload"
						width="960px"
						top="32vh"
						:visible.sync="dialogUploadVisible"
						:before-close="handleUploadClose">
						<upload-material
							ref="uploadImage"
							@get-uplod-action="handleUploadAction"
							:upload-type="false">
						</upload-material>
					</el-dialog>
					<el-dialog
						:title="$t('seller.dialogTitle.goods')"
						width="980px"
						:visible.sync="dialogVisibleGoods"
						:before-close="handleCloseGoods">
						<div class="dialog-goods">
							<el-scrollbar style="height: 520px;">
								<el-checkbox-group v-model="checkGoods">
									<el-checkbox v-for="(item,index) in dialogGoods" :key="index" :label="item.goods_id">
										<div class="goods-item">
											<img class="pic" :src="item.default_image" alt="">
											<div class="item-main">
												<div class="name">{{item.goods_name}}</div>
												<div class="price">￥{{item.price}}</div>
											</div>
										</div>
									</el-checkbox>
								</el-checkbox-group>
								<div class="uu-footer" v-if="listTotalGoods>0">
									<el-pagination
										@size-change="handleSizeChangeGoods"
										@current-change="handleCurrentChangeGoods"
										:current-page.sync="pagecurrGoods"
										:page-size="pagesizeGoods"
										layout="total, prev, pager, next"
										:prev-text="$t('seller.pagination.prevText')"
										:next-text="$t('seller.pagination.nextText')"
										:total="listTotalGoods">
									</el-pagination>
								</div>
							</el-scrollbar>
							<div style="text-align: right;" slot="footer">
								<el-button @click="handleCloseGoods" size="medium">{{$t('seller.actions.cancel')}}</el-button>
								<el-button type="primary" @click="handleConfirmGoods" size="medium">{{$t('seller.actions.confirm')}}</el-button>
							</div>
						</div>
					</el-dialog>
					<el-dialog
						:title="$t('seller.dialogTitle.coupon')"
						width="960px"
						:visible.sync="dialogVisibleCoupon"
						:before-close="handleCloseCoupon">
						<div class="dialog-coupon">
							<el-scrollbar style="height: 520px;">
								<el-table
									:data="dialogCoupon"
									@current-change="handleCurrentChangeCouponTable"
									style="width: 100%;">
									<el-table-column width="55" type="index" align="center">
										<template slot-scope="scope">
											<el-radio v-model="checkCoupon.coupon_id" :disabled="disabledCoupon(scope.row.coupon_id)" :label="scope.row.coupon_id">{{ }}</el-radio>
										</template>
									</el-table-column>
									<el-table-column :label="$t('seller.tableHead.name')" prop="name">
									</el-table-column>
									<el-table-column :label="$t('seller.tableHead.money')">
										<template slot-scope="scope">￥{{scope.row.money}}</template>
									</el-table-column>
									<el-table-column :label="$t('seller.tableHead.validity')">
										<template slot-scope="scope">{{scope.row.use_end_time | parseTime('{y}-{m}-{d}')}}</template>
									</el-table-column>
									<el-table-column :label="$t('seller.tableHead.useLimits')">
										<template slot-scope="scope">{{$t('seller.pageEditor.wpText2-1')}}{{scope.row.quota}}{{$t('seller.pageEditor.wpText2-2')}}</template>
									</el-table-column>
								</el-table>
							<div class="uu-footer" v-if="listTotalCoupon>0">
								<el-pagination
									@size-change="handleSizeChangeCoupon"
									@current-change="handleCurrentChangeCoupon"
									:current-page.sync="pagecurrCoupon"
									:page-size="pagesizeCoupon"
									layout="total, prev, pager, next"
									:prev-text="$t('seller.pagination.prevText')"
									:next-text="$t('seller.pagination.nextText')"
									:total="listTotalCoupon">
								</el-pagination>
							</div>
							</el-scrollbar>
							<div style="text-align: right;" slot="footer">
								<el-button @click="handleCloseCoupon" size="medium">{{$t('seller.actions.cancel')}}</el-button>
								<el-button type="primary" @click="handleConfirmCoupon" size="medium">{{$t('seller.actions.confirm')}}</el-button>
							</div>
						</div>
					</el-dialog>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { fetchMobile, updateMobile, releaseMobile, mobileGoods, fetchModule, moduleList, createModule, updateModule } from '@/api/seller/decorate'
	import { fetchList } from '@/api/seller/goods'
	import { getCouponList } from '@/api/seller/coupon'
	import draggable from 'vuedraggable'
	import decorateRecom from '@/components/seller/decorateRecom.vue'
	import uploadMaterial from '@/components/seller/uploadMaterial.vue'
	import { normalOSSUpload } from '@/utils/upload';
	
	const defaultModule = {
		page_module_id: 0,
		belong_container: '',
		content: {
			attr_type: '1',
			attr_thumb: [],
			attr_data: '',
			attr_link: '',
			attr_status: '1',
			attr_coupon: '2',
			attr_banner: '',
		},
		name: '',
		type: '',
	}
	export default {
		components: {
			draggable,
			decorateRecom,
			uploadMaterial,
		},
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
			return {
				decorateInfo: {},
				keyword: '',
				moduleActive: [1],
				moduleList: [],
				previewerList: [
					{name:'轮播图',number: 20,image:'',type:'1',belong_container:'carousel',page_module_id:0,content:''},
					{name:'猜你喜欢',number: 0,image:'',type:'0',belong_container:'like',page_module_id:0,content:''},
				],
				panelActive: ['1'],
				panelIndex: -1,
				panelForm: '',
				moduleForm: Object.assign({} ,defaultModule),
				thumbList: [{img_url:'',link_url:''}],
				goodsList: [],
				couponList: [{name:'',quota:''},{name:'',quota:''}],
				couponIndex: -1,
				loadingUplaod: false,
				// 弹窗-模块列表
				dialogVisibleModule: false,
				pagesizeModule: 10,
				pagecurrModule: 1,
				listTotalModule: 0,
				dialogModule: [],
				checkModule: '',
				// 弹窗-图片素材
				dialogUploadVisible: false,
				uploadType: '',
				uploadIndex: '',
				// 弹窗-商品
				dialogVisibleGoods: false,
				pagesizeGoods: 10,
				pagecurrGoods: 1,
				listTotalGoods: 0,
				dialogGoods: [],
				checkGoods: [],
				// 弹窗-优惠券
				dialogVisibleCoupon: false,
				pagesizeCoupon: 10,
				pagecurrCoupon: 1,
				listTotalCoupon: 0,
				dialogCoupon: [],
				checkCoupon: {},
				rankGoodsList: [],
				moreGoodsList: [],
				basicSetupOption: this.$t('seller.pageEditor.basicSetupOption'),
				subUndertakeOption: this.$t('seller.pageEditor.subUndertakeOption'),
				vedioSubpageOption: this.$t('seller.pageEditor.vedioSubpageOption'),
				goodsStockOption: this.$t('seller.pageEditor.goodsStockOption'),
				couponAddOption: this.$t('seller.pageEditor.couponAddOption')
			}
		},
		created() {
			this.getModuleList();
			this.getMoreGoodsList('common');
			this.getMoreGoodsList('sales');
			this.getDecorateInfo();
			this.getDialogGoods();
			this.getDialogCoupon();
			let _favicon = document.querySelector('link[rel="icon"]');
			_favicon.href = '/favicon-shop.ico';
		},
		methods: {
			validateThumb(rule, value, callback){
				let flag = 0;
				value.forEach(item=>{
					if(!item.img_url){
						flag = 1;
					}
				})
				if(flag==1){
					callback(new Error(this.$t('seller.validator.complete')));
				}else {
					callback();
				}
			},
			getModuleList() {
				this.moduleList = [{
					id: 1,
					name: '图文类',
					children: [
						{name:'轮播图',type:'1',number: 20,image: require('@/assets/images/seller/decorate-steps-375-263.png'),belong_container:'carousel',page_module_id:0,content:'',style_type: '1'},
						{name:'单图海报',type:'1',number: 20,image: require('@/assets/images/seller/decorate-steps-375-586.png'),belong_container: 'poster-single',page_module_id:0,content:'',style_type: '1'},
						// {name:'店铺热搜',type:'1',number: 1,image:'',belong_container:'shop-search',page_module_id:0,content:'',style_type: '1'},
						{name:'文字标题',type:'1',number: 20,image: require('@/assets/images/seller/decorate-steps-375-53.png'),belong_container:'text',page_module_id:0,content:'文字标题',style_type: '1'},
						// {name:'多热区切图',type:'1',number: 20,belong_container:'',type: 'cut-graph',page_module_id:0,content:'',style_type: '1'},
						// {name:'群聊入口慕模块',type:'1',number: 1,belong_container:'',type:'group-chat',page_module_id:0,content:'',style_type: '1'},
						// {name:'人群海报',type:'1',number: 1,image:'',belong_container:'poster-crowd',page_module_id:0,content:'',style_type: '1'},
						// {name:'免息专属票条',type:'1',number: 1,image:'',belong_container:'free-ticket',page_module_id:0,content:'',style_type: '1'},
					]
				},{
					id: 2,
					name: '视频类',
					children: [
						{name:'单视频',type:'3',number: 2,image: require('@/assets/images/seller/decorate-steps-375-258.png'),belong_container: 'video-single',page_module_id:0,content:'',style_type: '1'},
					]
				},{
					id: 3,
					name: 'LiveCard',
					children: [
						// {name:'测款选品',type:'5',number: 1,image:'',belong_container: 'test-select',page_module_id:0,content:'',style_type: '1'},
						// {name:'口腔检测livercard',type:'5',number: 1,image:'',belong_container: 'oral-detection',page_module_id:0,content:'',style_type: '1'},
					]
				},{
					id: 4,
					name: '宝贝类',
					children: [
						{name:'排行榜',type:'2',number: 1,image: require('@/assets/images/seller/decorate-steps-375-497.png'),belong_container:'goods-ranking',page_module_id:0,content:'',style_type: '1'},
						{name:'智能宝贝推荐',type:'2',number: 20,image: require('@/assets/images/seller/decorate-steps-375-635.png'),belong_container: 'goods-recommend',page_module_id:0,content:'',style_type: '1'},
						// {name:'系列主题宝贝',type:'2',number: 20,image:'',belong_container: 'goods-series',page_module_id:0,content:'',style_type: '1'},
					]
				},{
					id: 5,
					name: '营销互动类',
					children: [
						{name:'店铺优惠券',type:'4',number: 1,image: require('@/assets/images/seller/decorate-steps-375-96.png'),belong_container:'coupon-shop',page_module_id:0,content:'',style_type: '1'},
					]
				}]
			},
			getMoreGoodsList(type){
				let params = {
					d_type: type,
					page_index: 6,
					page_size: 0
				}
				mobileGoods(params).then(response => {
					if(type=='common'){
						this.moreGoodsList = response.data.list;
					}else if(type=='sales'){
						this.rankGoodsList = response.data.list;
					}
				})
			},
			getDecorateInfo(){
				let params = {
					wap_store_page_id: this.$route.params.id
				}
				fetchMobile(params).then(response => {
					const { data } = response;
					this.decorateInfo = data;
					this.previewerList = data.content?JSON.parse(data.content):this.previewerList;
				})
			},
			getModuleInfo(id=0){
				fetchModule({page_module_id: id}).then(response => {
					const { data } = response
					if(this.panelForm.belong_container === data.belong_container){
						let content = JSON.parse(data.content);
						this.moduleForm = JSON.parse(JSON.stringify(data));
						this.moduleForm.content = JSON.parse(this.moduleForm.content);
						if(data.belong_container==='carousel'){
							this.thumbList = content.attr_thumb?content.attr_thumb:[{img_url:'',link_url:''}];
							this.previewerList[this.panelIndex].content = content.attr_thumb?content.attr_thumb[0].img_url:'';
						}else if(data.belong_container==='goods-recommend' && content.attr_data){
							this.getGoodsList(content.attr_data);
						}else if(data.belong_container==='coupon-shop'){
							this.getCouponList(content.attr_data,content.attr_coupon);
						}
					}else {
						this.moduleForm = Object.assign({} ,defaultModule)
						this.moduleForm.belong_container = this.panelForm.belong_container;
						this.moduleForm.type = this.panelForm.type;
						this.previewerList[this.panelIndex].page_module_id = 0;
						this.previewerList[this.panelIndex].content = '';
					}
				})
			},
			handleModuleChange(val) {
				console.log(val);
			},
			handlePanelChange(val) {
				console.log(val);
			},
			onDraggableEnd(event){
				this.panelIndex = event.newIndex;
				this.panelForm = JSON.parse(JSON.stringify(this.previewerList[event.newIndex]));
				if(this.panelForm.page_module_id>0){
					this.getModuleInfo(this.panelForm.page_module_id);
				}else {
					this.moduleForm = Object.assign({} ,defaultModule)
					this.moduleForm.belong_container = this.panelForm.belong_container;
					this.moduleForm.type = this.panelForm.type;
				}
				this.handleSavePreviewer(); // 保存数据
			},
			onDraggableEnd2(event){
				if(this.panelForm){
					this.panelIndex = event.newIndex;
				}
				this.handleSavePreviewer(); // 保存数据
			},
			onDraggableMove(val,event){
				let data = val.draggedContext.element;
				let num = 0;
				this.previewerList.forEach(item=>{
					if(item.name==data.name){
						num++;
					}
				})
				if(num===data.number || num>data.number) return false;
				return true;
			},
			onDraggableMove2(val,event){
				return false
			},
			getItemText(val){
				let num = 0;
				this.previewerList.forEach(item=>{
					if(item.name==val.name){
						num++;
					}
				})
				return num+'/'+val.number;
			},
			handlePreviewerChange(val,index=-1){
				this.getDecorateInfo();
				if(val){
					this.panelIndex = index;
					this.panelForm = JSON.parse(JSON.stringify(val));
					if(this.panelForm.page_module_id>0){
						this.getModuleInfo(this.panelForm.page_module_id);
					}else {
						this.moduleForm = Object.assign({} ,defaultModule)
						if(this.panelForm.belong_container==='carousel'){
							this.thumbList = [{img_url:'',link_url:''}];
						}else if(this.panelForm.belong_container==='goods-recommend'){
							this.goodsList = [];
						}else if(this.panelForm.belong_container==='coupon-shop'){
							this.couponList = [{name:'',quota:''},{name:'',quota:''}];
						}
						this.moduleForm.belong_container = this.panelForm.belong_container;
						this.moduleForm.type = this.panelForm.type;
					}
				}else{
					this.panelIndex = -1;
					this.panelForm = '';
					this.moduleForm = Object.assign({} ,defaultModule)
					this.thumbList = [{img_url:'',link_url:''}];
					this.goodsList = [];
					this.couponList = [{name:'',quota:''},{name:'',quota:''}];
				}
			},
			handlePreviewerRemove(index){
				this.panelIndex = -1;
				this.panelForm = '';
				this.previewerList.splice(index,1);
				this.handleSavePreviewer(); // 保存数据
			},
			handlePreviewerUp(index){
				if(index>0){
					let list = this.previewerList;
					this.panelIndex = index-1;
					this.previewerList[index]=list.splice(index-1,1,list[index])[0];
					this.handleSavePreviewer(); // 保存数据
				}
			},
			handlePreviewerDown(index){
				if(index!==this.previewerList.length-1) {
					let list = this.previewerList;
					this.panelIndex = index+1;
					this.previewerList[index]=list.splice(index+1,1,list[index])[0];
					this.handleSavePreviewer(); // 保存数据
				}
			},
			// 模块
			getDialogModule(){
				let params = {
					belong_container: this.panelForm.belong_container,
					page_index: (this.pagecurrModule-1)*this.pagesizeModule,
					page_size: this.pagesizeModule
				}
				moduleList(params).then(response => {
					this.dialogModule = response.data.list || []
					if(this.dialogModule.length>0){
						this.dialogModule.forEach(item=>{
							item.content = JSON.parse(item.content);
						})
					}
					this.listTotalModule = parseInt(response.data.total)
				})
			},
			handleShowModule(){
				this.dialogVisibleModule= true;
				this.pagecurrModule = 1;
				this.getDialogModule();
			},
			handleCancelModule(){
				this.dialogVisibleModule= false;
			},
			handleConfirmModule(){
				this.getModuleInfo(this.checkModule);
				this.dialogVisibleModule= false;
			},
			handleSizeChangeModule(val){
				this.pagesizeModule = val;
			},
			handleCurrentChangeModule(val){
				this.pagecurrModule = val;
				this.getDialogModule();
			},
			// 轮播图
			handleAddImage(){
				if(this.thumbList.length<4){
					this.thumbList.push({img_url:'',link_url:''});
				}
			},
			handleUpImage(index){
				if(index>0){
					let list = this.thumbList;
					this.thumbList[index]=list.splice(index-1,1,list[index])[0];
				}
			},
			handleDownImage(index){
				if(index!==this.thumbList.length-1) {
					let list = this.thumbList;
					this.thumbList[index]=list.splice(index+1,1,list[index])[0];
				}
			},
			handleRemoveImage(index){
				this.thumbList.splice(index,1);
			},
			// 素材
			handleUploadClose(){
				this.uploadType = '';
				this.uploadIndex = '';
				this.dialogUploadVisible = false;
			},
			handleUploadOpen(type='',index=''){
				this.uploadType = type;
				this.uploadIndex = index;
				this.dialogUploadVisible = true;
			},
			handleUploadAction(data){
				if(this.uploadType=='thumbs'){
					this.thumbList[this.uploadIndex].img_url = data;
				}else if(this.uploadType=='banner'){
					this.moduleForm.content.attr_banner = data;
				}else {
					this.moduleForm.content.attr_data = data;
				}
				this.uploadType = '';
				this.uploadIndex = '';
				this.dialogUploadVisible = false;
			},
			// 视频上传
			async httpRequestVideoUpload(value){
				const { file } = value;
				const istype = file.type.indexOf('video');
				const isSize = file.size / 1024 / 1024 < 5;
				if (istype<0) {
					this.$message.error(this.$t('seller.upload.videoType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.videoSize5M'));
					return false;
				}
				this.loadingUplaod = true;
				const { code, data } = await normalOSSUpload(file,'video');
				if(code==200){
					this.moduleForm.content.attr_data = data.url;
				}
				this.loadingUplaod = false;
			},
			// 商品
			getDialogGoods(){
				let params = {
					is_show: 1,
					page_index: (this.pagecurrGoods-1)*this.pagesizeGoods,
					page_size: this.pagesizeGoods
				}
				fetchList(params).then(response => {
					this.dialogGoods = response.data.list
					if(this.dialogGoods.length>0){
						this.dialogGoods.forEach(item=>{
							item.content = item.content?JSON.parse(item.content):'';
						})
					}
					this.listTotalGoods = parseInt(response.data.total);
				})
			},
			getGoodsList(str){
				let params = {
					is_show: 1,
					goods_ids: str,
					page_index: 0,
					page_size: 50
				}
				fetchList(params).then(response => {
					this.goodsList = response.data.list;
					let check = [];
					this.goodsList.forEach(item=>{
						check.push(item.goods_id);
					})
					this.checkGoods = check;
				})
			},
			handleSizeChangeGoods(val){
				this.pagesizeGoods = val;
			},
			handleCurrentChangeGoods(val){
				this.pagecurrGoods = val;
				this.getDialogGoods();
			},
			handleRecommendChange(val){
				this.previewerList[this.panelIndex].style_type = val;
			},
			handleOpenGoods(){
				this.dialogVisibleGoods = true;
			},
			handleCloseGoods(){
				let ids = [];
				this.goodsList.forEach(item=>{
					ids.push(item.goods_id);
				})
				this.checkGoods = ids;
				this.dialogVisibleGoods = false;
			},
			handleConfirmGoods(){
				let str = this.checkGoods.join(',');
				if(str){
					this.getGoodsList(str);
				}else {
					this.goodsList = [];
				}
				this.dialogVisibleGoods = false;
			},
			// 优惠券
			getDialogCoupon(){
				let params = {
					status: 1,
					type: 1,
					page_index: (this.pagecurrCoupon-1)*this.pagesizeCoupon,
					page_size: this.pagesizeCoupon
				}
				getCouponList(params).then(response => {
					this.dialogCoupon = response.data.list
					if(this.dialogCoupon.length>0){
						this.dialogCoupon.forEach(item=>{
							item.content = item.content?JSON.parse(item.content):'';
						})
					}
					this.listTotalCoupon = parseInt(response.data.total)
				})
			},
			getCouponList(str,num){
				let params = {
					status: 1,
					type: 1,
					coupon_id: str,
					page_index: 0,
					page_size: 10
				}
				getCouponList(params).then(response => {
					if(response.data.list.length>0){
						this.couponList = response.data.list;
						for(let i = 0;i<num-response.data.total;i++){
							this.couponList.push({name:'',quota:''});
						}
					}else {
						this.couponList = [{name:'',quota:''},{name:'',quota:''}];
					}
				})
			},
			handleSizeChangeCoupon(val){
				this.pagesizeCoupon = val;
			},
			handleCurrentChangeCoupon(val){
				this.pagecurrCoupon = val;
				this.getDialogCoupon();
			},
			handleOpenCoupon(index,row){
				this.couponIndex = index;
				this.checkCoupon = row;
				this.dialogVisibleCoupon = true;
			},
			handleCouponChange(val){
				this.couponList.splice(val);
				let length = this.couponList.length;
				for(let i=0;i<val-length;i++){
					this.couponList.push({name:'',quota:''});
				}
			},
			handleUpCoupon(index){
				if(index>0){
					let list = this.couponList;
					this.couponList[index]=list.splice(index-1,1,list[index])[0];
				}
			},
			handleDownCoupon(index){
				if(index!==this.couponList.length-1) {
					let list = this.couponList;
					this.couponList[index]=list.splice(index+1,1,list[index])[0];
				}
			},
			handleCloseCoupon(){
				this.couponIndex = -1;
				this.dialogVisibleCoupon = false;
			},
			handleCurrentChangeCouponTable(row){
				let flag = true;
				this.couponList.forEach(item=>{
					if(item.coupon_id == row.coupon_id){
						flag = false;
					}
				})
				if(flag){
					this.checkCoupon = row;
				}
			},
			disabledCoupon(id){
				let flag = false;
				this.couponList.forEach(item=>{
					if(item.coupon_id == id){
						flag = true;
					}
				})
				return flag;
			},
			handleConfirmCoupon(){
				if(this.checkCoupon.coupon_id>0){
					this.dialogVisibleCoupon = false;
					this.couponList[this.couponIndex] = this.checkCoupon;
				}else{
					this.$message.error(this.$t('seller.errorMsg.coupon'));
				}
			},
			// 装修数据存储
			handleSavePreviewer(){
				this.decorateInfo.content = JSON.stringify(this.previewerList);
				updateMobile(this.decorateInfo).then(() => {
				})
			},
			handleModuleClose(){
				this.panelIndex = -1;
				this.panelForm = '';
				this.moduleForm = Object.assign({} ,defaultModule)
				this.thumbList = [{img_url:'',link_url:''}];
			},
			// 模块数据存储
			handleAddModule(){
				let that = this;
				if(that.panelForm.belong_container==='carousel'){
					that.moduleForm.content.attr_data = '';
					that.moduleForm.content.attr_thumb = that.thumbList;
				}else if(that.panelForm.belong_container==='goods-recommend'){
					that.moduleForm.content.attr_data = that.checkGoods.join(',');
				}else if(that.panelForm.belong_container==='coupon-shop'){
					let ids = [];
					that.couponList.forEach(item=>{
						if(item.coupon_id && item.coupon_id>0){
							ids.push(item.coupon_id);
						}
					})
					that.moduleForm.content.attr_data = ids.join(',');
				}
				that.$refs.moduleForm.validate(valid => {
					if (valid) {
						let that = this;
						let loading = that.$loading();
						let param = {
							belong_container: that.moduleForm.belong_container,
							content: JSON.stringify(that.moduleForm.content),
							name: that.moduleForm.name,
							type: that.moduleForm.type
						}
						if(parseInt(that.moduleForm.page_module_id)>0){
							param.page_module_id = that.moduleForm.page_module_id;
							updateModule(param).then(response => {
								that.$message.success(that.$t('seller.successMsg.save'));
								that.panelForm.page_module_id= that.moduleForm.page_module_id;
								if(that.panelForm.belong_container=='carousel'){
									that.panelForm.content = that.moduleForm.content.attr_thumb[0].img_url;
								}else {
									that.panelForm.style_type = that.moduleForm.content.attr_type;
									that.panelForm.content = that.moduleForm.content.attr_data;
								}
								that.$set(that.previewerList,that.panelIndex,that.panelForm);
								that.handleSavePreviewer();
								loading.close();
							}).catch((err)=>{
								loading.close();
							})
						}else{
							createModule(param).then(response => {
								that.$message.success(that.$t('seller.successMsg.save'));
								that.panelForm.page_module_id= response.data.page_module_id;
								if(that.panelForm.belong_container=='carousel'){
									that.panelForm.content = that.moduleForm.content.attr_thumb[0].img_url;
								}else {
									that.panelForm.content = that.moduleForm.content.attr_data;
								}
								that.previewerList[that.panelIndex] = that.panelForm;
								that.handleSavePreviewer();
								loading.close();
							}).catch((err)=>{
								loading.close();
							})
						}
					} else {
						return false;
					}
				});
			},
			imageLoadError(e){
				e.target.src = require('@/assets/images/seller/decorate-steps-750-376.png');
				e.target.style.objectFit = 'fill';
			},
			imageGoodsError(e){
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			handlePublish(){
				let that = this;
				that.decorateInfo.content = JSON.stringify(that.previewerList);
				that.$confirm(that.$t('seller.confirm.publishPage'),that.$t('seller.confirm.title'),{
					confirmButtonText: that.$t('seller.actions.confirm'),
					cancelButtonText: that.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					let param = {
						wap_store_page_id: that.decorateInfo.wap_store_page_id
					}
					releaseMobile(param).then(() => {
						that.$message.success(that.$t('seller.successMsg.publish'))
					})
				}).catch(() => {
					that.$message(that.$t('seller.cancelMsg.publish'));
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	#app {
		.wp-editor {
			min-width: 1200px;
			height: 100%;
			background-color: #e6e9ed;

			.uu-header {
				height: 60px;
				padding: 0 28px;
				z-index: 3;
				position: relative;
				background-color: #FFFFFF;
				border-bottom: 1px solid #e6e9ed;

				.header-wrap {
					float: left;
					padding: 12px 0;
					height: 36px;
					line-height: 36px;

					.logo {
						color: #333333;
						height: 36px;
						font-size: 24px;
						line-height: 36px;
						display: inline-block;
						vertical-align: middle;
						img {
							height: 100%;
						}
					}

					span {
						display: inline-block;
						vertical-align: middle;
						margin-left: 20px;
					}
				}

				.action {
					float: right;
					padding: 16px 0;
					white-space: nowrap;

					li {
						margin-left: 10px;
						display: inline-block;
					}
				}
			}

			.uu-content {
				height: calc(100% - 61px);
				::v-deep .el-scrollbar {
					height: 100%;
				
					.el-scrollbar__wrap {
						overflow-x: hidden;
					}
				}

				.content-wrap {
					width: 100%;
					height: 100%;
					display: flex;
					// flex: 1;
					overflow-x: auto;

					.module-drawer {
						width: 282px;
						height: 100%;
						background-color: #FFFFFF;
						box-shadow: 0 0 5px -2px rgba(0, 0, 0, .2);
						flex-shrink: 0;

						.module-drawer-head {
							padding: 0 20px;

							h2 {
								line-height: 30px;
								font-weight: 500;
								font-size: 20px;
								margin-bottom: 10px;
								margin-top: 16px;
							}

							.text {
								font-size: 14px;
								height: 24px;
								line-height: 24px;
								color: rgb(153, 153, 153);
								margin-bottom: 10px;

								.el-icon-question {
									font-size: 16px;
									margin-left: 6px;
								}
							}

							::v-deep .el-input {
								.el-input__inner {
									border-right: none;
								}

								.el-input__inner:focus {
									border-color: #DCDFE6;
								}

								.el-input-group__append {
									background-color: transparent;
								}
							}

							.btn--append {
								height: 36px;
								line-height: 36px;
								text-align: center;
								margin: -10px -20px;
								cursor: pointer;
							}
						}

						.module-drawer-tabs {
							height: 41px;
							border-bottom: 1px solid #dcdee3;

							li {
								display: inline-block;
								text-align: center;
								height: 42px;
								line-height: 20px;
								width: 50%;
								font-size: 14px;
								padding: 10px 16px;
								box-sizing: border-box;
								border-bottom: 2px solid transparent;
							}

							li.active {
								color: $--seller-primary;
								border-bottom-color: $--seller-primary;
							}
						}

						.module-drawer-main {
							height: calc(100% - 168px);

							::v-deep .el-collapse {
								.el-collapse-item__header {
									height: 20px;
									line-height: 20px;
									padding: 8px 16px;
									border-bottom: none;

									.el-collapse-item__arrow {
										margin-right: 0;
									}
								}

								.el-collapse-item__wrap {
									border-bottom: none;
									.el-collapse-item__content {
										padding-bottom: 0;
									}
								}
							}

							.module-list {
								padding: 12px 16px;
								display: flex;
								flex-wrap: wrap;
								justify-content: space-between;

								.item {
									width: 116px;
									margin-bottom: 12px;
									.item-basket {
										height: 0;
										overflow: hidden;
									}
									.item-pic {
										width: 116px;
										height: 116px;
										padding: 5px;
										box-sizing: border-box;
										overflow: hidden;
										border-radius: 5px;
										background-color: rgb(247, 248, 250);
										cursor: grab;

										img {
											width: 100%;
											height: 100%;
											object-fit: contain;
											border-radius: 5px;
										}
									}
									.item-title {
										line-height: 22px;
										max-height: 44px;
										color: rgb(74, 91, 109);
										margin: 6px 0px;
										overflow: hidden;
										text-align: center;
									}

									.item-num {
										color: rgb(159, 172, 185);
										font-size: 12px;
										line-height: 20px;
										text-align: center;
									}
								}
							}
						}
					}

					.previewer {
						flex: 1;
						min-width: 500px;
						height: 100%;
						display: block;
						.previewer-main {
							width: 375px;
							min-height: calc(100vh - 130px);
							margin: 30px auto;
							background-color: rgb(240,240,240);
							box-shadow: 0 0 5px rgba(0,0,0,.2);
							.previewer-head {
								height: 142px;
								margin-bottom: 8px;
								position: relative;
								background-image: url('~@/assets/images/seller/decorate-steps-1500-568.png');
								background-repeat: no-repeat;
								background-size: 100% auto;
								background-position: center top;
								.name {
									top: 100px;
									height: 22px;
									line-height: 22px;
									font-size: 16px;
									width: 100%;
									font-weight: 600;
									padding: 0 12px;
									position: absolute;
									box-sizing: border-box;
									display: inline-flex;
									align-items: center;
									span {
										max-width: 200px;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
										display: inline-block;
									}
									.el-icon-arrow-right {
										border-radius: 50%;
										font-size: 12px;
										padding: 1px;
										font-weight: bold;
										border: 1px solid #333333;
										margin-left: 5px;
									}
								}
							}
							.previewer-list {
								.item-draggable {
									display: none;
								}
								.item-num {
									display: none;
								}
								.item {
									margin-top: 12px;
									position: relative;
								}
								.item-basket {
									height: 80px;
									display: flex;
									align-items: center;
									justify-content: center;
									overflow: hidden;
									margin: 6px 0;
									font-size: 12px;
									color: $--seller-primary;
									background-color: $--seller-draggable-background;
									transition-duration: 200ms;
								}
								.item-basket.gczpge {
									height: 80px;
								}
								.item-main {
									cursor: pointer;
									position: relative;
								}
								.item-title {
									top: 0;
									left: -45px;
									width: 36px;
									color: rgb(153,153,153);
									font-size: 14px;
									line-height: 16px;
									text-align: center;
									overflow-wrap: break-word;
									border-radius: 4px;
									background-color: #FFFFFF;
									opacity: 0;
									padding: 12px;
									box-sizing: border-box;
									position: absolute;
								}
								.item-pic {
									width: 357px;
									overflow: hidden;
									margin: 0 auto;
									border-radius: 12px;
									img {
										width: 375px;
										margin-left: -9px;
										margin-right: -9px;
									}
									video {
										width: 375px;
										margin-left: -9px;
										margin-right: -9px;
										object-fit: cover;
									}
								}
								.item-list {
									overflow: hidden;
									padding-bottom: 10px;
									.item-list-head {
										margin: 20px 10px;
										img {
											width: 200px;
											margin: 0 auto;
											display: block;
										}
									}
									.item-list-main {
										padding: 0 10px;
										.item-goods {
											float: left;
											width: 174px;
											overflow: hidden;
											border-radius: 5px;
											background-color: #FFFFFF;
											padding-bottom: 10px;
											margin-bottom: 10px;
											.pic {
												width: 174px;
												height: 174px;
												object-fit: cover;
											}
											.name {
												padding: 0 10px;
												height: 15px;
												line-height: 15px;
												font-size: 13px;
												overflow: hidden;
												text-overflow: ellipsis;
												margin-top: 10px;
											}
											.text {
												padding: 0 10px;
												margin-top: 10px;
												height: 18px;
												line-height: 18px;
												font-size: 12px;
												.price {
													color: rgb(255,85,0);
													em {
														font-size: 14px;
													}
												}
												.sales {
													color: #999999;
													margin-left: 5px;
												}
											}
										}
										.item-goods:nth-child(2n){
											margin-left: 7px;
										}
									}
								}
								.item:hover {
									box-shadow: 0 0 8px rgba(0,0,0,.2);
								}
								.item:hover .item-title {
									opacity: .5;
								}
								.item-overlay {
									top: 0;
									width: 100%;
									height: 100%;
									cursor: pointer;
									box-sizing: border-box;
									position: absolute;
									border: 2px solid transparent;
									border-radius: 4px;
								}
								.item-overlay.active {
									border-color: $--seller-primary;
								}
								.item-action {
									top: 0;
									right: -45px;
									position: absolute;
									color: rgb(153,153,153);
									.li {
										width: 30px;
										margin-bottom: 8px;
										border-radius: 4px;
										background-color: #FFFFFF;
										i {
											width: 30px;
											height: 30px;
											line-height: 30px;
											text-align: center;
											font-size: 16px;
											cursor: pointer;
										}
										.el-icon-top,.el-icon-bottom {
											font-weight: bold;
										}
									}
									.active {
										color: $--seller-primary;
									}
									.disabled {
										color: rgb(204,204,204);
									}
								}
								.item-notice {
									margin: 0 10px;
									height: 34px;
									padding: 9px 16px;
									border-radius: 12px;
									display: flex;
									align-items: center;
									background-color: #FFFFFF;
									.icon-notice {
										width: 34px;
										height: 34px;
									}
									span {
										flex: 1;
										font-size: 16px;
										color: #000000;
										padding-left: 10px;
										white-space: nowrap;
										overflow: hidden;
										text-overflow: ellipsis;
									}
									.el-icon-arrow-right {
										font-size: 16px;
									}
								}
								.item-ranking {
									margin: 0 10px;
									.rank-tabs {
										height: 38px;
										margin: 0 10px;
										border-radius: 22px;
										background-color: #FFFFFF;
										padding: 4px;
										display: flex;
										.li {
											flex: 1;
											height: 38px;
											line-height: 38px;
											text-align: center;
											font-size: 16px;
											color: #333333;
											border-radius: 19px;
										}
										.li.active {
											color: rgb(255, 80, 0);
											background-color: rgb(255, 241, 235);
										}
									}
									.rank-list {
										padding: 14px 10px;
										border-radius: 10px;
										margin-top: 10px;
										background-color: #FFFFFF;
										.li {
											display: flex;
											position: relative;
											margin-bottom: 10px;
											.pic {
												width: 119px;
												height: 119px;
												object-fit: cover;
											}
											.li-main {
												flex: 1;
												width: 0;
												font-size: 13px;
												padding-left: 10px;
												.name {
													height: 18px;
													line-height: 18px;
													overflow: hidden;
													text-overflow: ellipsis;
													white-space: nowrap;
													margin-top: 6px;
												}
												.text {
													color: #999;
													height: 18px;
													line-height: 18px;
													margin-top: 10px;
													span {
														color: rgb(255, 80, 0);
													}
												}
												.icon-hot {
													width: 13px;
													height: 13px;
													display: inline-block;
													vertical-align: middle;
													background-image: url('~@/assets/images/seller/decorate-steps-52-52.png');
													background-size: 100% auto;
													background-repeat: no-repeat;
												}
												.icon-top {
													top: -6px;
													left: 6px;
													width: 34px;
													height: 40px;
													position: absolute;
												}
												.price {
													color: #999;
													height: 24px;
													line-height: 24px;
													margin-top: 42px;
													span {
														color: rgb(255, 80, 0);
													}
													em {
														font-size: 18px;
													}
												}
											}
										}
										.li-more {
											color: #999999;
											font-size: 14px;
											text-align: center;
											line-height: 1;
										}
									}
								}
								.item-recommend {
									.recom-list {
										padding: 10px;
										.li {
											display: flex;
											margin-bottom: 10px;
											background-color: #FFFFFF;
											border-radius: 10px;
											overflow: hidden;
											.pic {
												width: 140px;
												height: 140px;
												object-fit: cover;
												flex-shrink: 0;
											}
											.li-main {
												padding: 8px 10px;
												.name {
													height: 40px;
													line-height: 20px;
													font-size: 14px;
													overflow: hidden;
													text-overflow: ellipsis;
													display: -webkit-box;
													-webkit-box-orient: vertical;
													-webkit-line-clamp: 2;
												}
												.price {
													color: #999;
													height: 24px;
													line-height: 24px;
													margin-top: 60px;
													font-size: 13px;
													span {
														color: rgb(255, 80, 0);
													}
													em {
														font-size: 18px;
													}
												}
											}
										}
										.li:last-child {
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}

					.layout-manager {
						width: 172px;
						background-color: #FFFFFF;
						.layout-manager-title {
							padding: 0 22px;
							height: 52px;
							display: flex;
							align-items: center;
							span {
								font-size: 16px;
								font-weight: 500;
							}
							i {
								color: #666666;
								font-size: 20px;
								cursor: pointer;
							}
							.el-icon-close:hover {
								color: $--seller-primary;
							}
						}
						.layout-manager-list {
							padding: 12px 0;
							height: calc(100% - 92px);
							.item {
								width: 148px;
								padding: 12px 16px;
								box-sizing: border-box;
								border-radius: 4px;
								background-color: rgb(247,248,250);
								border: 1px solid rgb(247,248,250);
								margin: 0 auto 8px;
								height: 52px;
								line-height: 26px;
								cursor: pointer;
								.item-icon {
									width: 20px;
									height: 20px;
									display: inline-block;
									vertical-align: middle;
									cursor: grab;
									background-image: url('~@/assets/images/seller/decorate-steps-40-40.png');
									background-repeat: no-repeat;
									background-size: 40px auto;
								}
								.item-icon.icon1 {
									background-position: 0 0;
								}
								.item-icon.icon2 {
									background-position: -20px 0;
								}
								span {
									width: 86px;
									display: inline-block;
									vertical-align: middle;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									font-size: 14px;
									margin-left: 8px;
								}
							}
							.item.active {
								color: $--seller-primary;
								border-color: $--seller-primary;
								.item-icon.icon1 {
									background-position: 0 -20px;
								}
								.item-icon.icon2 {
									background-position: -20px -20px;
								}
							}
						}
					}

					.editor-panel {
						width: 408px;
						flex-shrink: 0;
						background-color: #FFFFFF;
						border-left: 1px solid rgb(215, 219, 224);
						.editor-panel-title {
							height: 52px;
							display: flex;
							align-items: center;
							padding: 0 15px;
							margin: 0 7px;
							border-bottom: 1px solid rgb(230,230,230);
							.name {
								font-size: 16px;
								font-weight: 500;
							}
							.next-btn {
								height: 24px;
								line-height: 24px;
								font-size: 14px;
								color: $--seller-primary;
							}
						}
						.editor-panel-main {
							height: calc(100% - 122px);
							position: relative;
							::v-deep .el-collapse {
								border: none;
								.el-collapse-item {
									border-bottom: 7px solid rgb(230,233,237);
									.el-collapse-item__header {
										height: 38px;
										line-height: 38px;
										padding-left: 22px;
										border-bottom: none;
									}
									.el-collapse-item__wrap {
										border-bottom: none;
										.el-collapse-item__content {
											padding: 12px 22px;
										}
									}
								}
								.el-collapse-item:last-child {
									border-bottom: none;
								}
							}
							.mt10 {
								margin-top: 10px;
							}
							.mt16 {
								margin-top: 16px;
							}
							.mb12 {
								margin-bottom: 12px;
							}
							.notice {
								color: #999999;
								font-size: 13px;
								line-height: 20px;
								margin-bottom: 5px;
								a {
									color: $--seller-primary;
								}
								.right {
									float: right;
								}
							}
							.thumb-list {
								.item {
									display: flex;
									align-items: center;
									margin-bottom: 10px;
									flex-direction: row;
									background-color: #F9F9F9;
									.item-move {
										width: 25px;
										display: flex;
										align-items: center;
										flex-direction: column;
										justify-content: space-between;
										i {
											font-size: 14px;
											color: #999999;
											margin: 5px 0;
											cursor: pointer;
											font-weight: bold;
										}
										i.disabled {
											color: #c9c9c9;
											cursor: not-allowed;
										}
									}
									.item-con {
										flex: 1;
										display: flex;
										flex-direction: column;
										padding: 10px 0;
									}
									.item-wrap {
										flex: 1;
										display: flex;
										align-items: center;
										margin-bottom: 5px;
										.el-icon-plus {
											width: 70px;
											height: 50px;
											font-size: 14px;
											line-height: 50px;
											text-align: center;
											background-color: #F4F4F4;
											box-shadow: 0 0 0 1px #F4F4F4 inset;
											cursor: pointer;
										}
										img {
											height: 50px;
											width: 70px;
											cursor: pointer;
											object-fit: cover;
										}
									}
									.item-wrap:last-child {
										margin-bottom: 0;
									}
									.item-del {
										width: 25px;
										display: flex;
										justify-content: center;
										i {
											color: #999999;
											cursor: pointer;
											font-size: 14px;
										}
										i.disabled {
											color: #c9c9c9;
											cursor: not-allowed;
										}
									}
								}
								.item:last-child {
									margin-bottom: 0;
								}
							}
							.goods-list {
								display: flex;
								flex-wrap: wrap;
								.item {
									width: 56px;
									height: 56px;
									flex-shrink: 0;
									margin-right: 10px;
									margin-bottom: 10px;
									position: relative;
									cursor: pointer;
									img {
										width: 56px;
										height: 56px;
										object-fit: cover;
									}
									.el-icon-more {
										top: 0;
										left: 0;
										width: 100%;
										height: 100%;
										position: absolute;
										color: #FFFFFF;
										line-height: 56px;
										text-align: center;
										font-size: 14px;
										background-color: rgba(0,0,0,.5);
									}
								}
							}
							.style-list {
								display: flex;
								flex-wrap: wrap;
								::v-deep .el-radio {
									width: 80px;
									margin-right: 10px;
									.el-radio__input {
										display: none;
									}
									.el-radio__label {
										padding: 0;
										display: block;
										.pic {
											width: 72px;
											height: 72px;
											padding: 3px;
											border-radius: 3px;
											cursor: pointer;
											border: 1px solid #D7DBE0;
											background-color: #F0F0F0;
											img {
												width: 100%;
												height: 100%;
												object-fit: contain;
												filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.12));
											}
										}
										.name {
											margin-top: 7px;
											font-size: 14px;
											text-align: center;
											height: 18px;
											line-height: 18px;
											overflow: hidden;
											text-overflow: ellipsis;
											white-space: nowrap;
										}
									}
									.el-radio__input.is-checked+.el-radio__label {
										.pic {
											border-color: $--seller-primary;
										}
									}
								}
							}
							.btn-add {
								height: 40px;
								line-height: 40px;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #F4F4F4;
								color: #666666;
								font-size: 12px;
								cursor: pointer;
								i {
									margin-right: 5px;
								}
							}
							.btn-thumb {
								height: 150px;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #F4F4F4;
								color: #666666;
								font-size: 12px;
								cursor: pointer;
								.btn-thumb-main {
									height: 150px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
								i {
									margin-right: 5px;
								}
								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
								::v-deep .el-upload {
									flex: 1;
									display: block;
								}
								video {
									width: 100%;
									height: 150px;
									display: block;
									object-fit: cover;
								}
							}
							::v-deep .el-form-item.is-error {
								.thumb-list {
									.item-wrap {
										.el-icon-plus {
											box-shadow: 0 0 0 1px #F56C6C inset;
										}
									}
								}
							}
						}
						.editor-panel-footer {
							padding: 16px 22px;
							background-color: #FFFFFF;
							border-top: 1px solid rgb(230,230,230);
						}
						.dialog-goods {
							::v-deep .el-checkbox-group {
								display: flex;
								flex-flow: wrap;
								.el-checkbox {
									width: 280px;
									flex-shrink: 0;
									display: flex;
									align-items: center;
									padding: 10px;
									margin: 10px 10px 10px 0;
									.el-checkbox__label {
										flex: 1;
									}
								}
								.el-checkbox:nth-child(3n){
									margin-right: 0;
								}
							}
							.goods-item {
								display: flex;
								.pic {
									width: 80px;
									height: 80px;
									object-fit: cover;
								}
								.item-main {
									flex: 1;
									padding-left: 10px;
									display: inline-block;
									.name {
										color: #333333;
										height: 60px;
										line-height: 20px;
										padding-top: 1px;
									}
									.price {
										color: #fa8b4a;
									}
								}
							}
						}
						.uu-footer {
							text-align: center;
							padding-top: 10px;
							::v-deep .el-pagination {
								button:disabled {
									background-color: transparent;
								}
								.el-pager {
									li {
										background-color: transparent;
									}
								}
							}
						}
						.dialog-module {
							::v-deep .el-dialog__body {
								background-color: rgb(247,248,250);
							}
						}
						.dialog-module-list {
							display: block;
							.el-radio {
								width: 180px;
								margin: 6px;
								border-radius: 6px;
								overflow: hidden;
								background-color: #FFFFFF;
								::v-deep .el-radio__input {
									top: 10px;
									left: 10px;
									position: absolute;
								}
								::v-deep .el-radio__label {
									padding-left: 0;
									display: block;
									.goods-item {
										.item-main {
											width: 168px;
											height: 168px;
											padding: 6px;
											display: flex;
											align-items: center;
											justify-content: center;
											background-color: rgb(230,233,237);
											img,video {
												width: 100%;
												height: 100%;
												object-fit: contain;
												border-radius: 4px;
											}
										}
										.item-notice {
											height: 18px;
											padding: 3px 6px;
											border-radius: 4px;
											display: flex;
											flex: 1;
											align-items: center;
											background-color: #FFFFFF;
											.icon-notice {
												width: 18px;
												height: 18px;
											}
											span {
												flex: 1;
												font-size: 12px;
												color: #000000;
												padding-left: 5px;
												white-space: nowrap;
												overflow: hidden;
												text-overflow: ellipsis;
											}
											.el-icon-arrow-right {
												font-size: 12px;
											}
										}
										.item-box {
											padding: 6px;
											.name {
												height: 24px;
												line-height: 24px;
												color: rgb(51,51,51);
												font-size: 16px;
												font-weight: 500;
												overflow: hidden;
												text-overflow: ellipsis;
												white-space: nowrap;
											}
											.text {
												font-size: 12px;
												height: 24px;
												line-height: 24px;
												color: rgb(153,153,153);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.uu-flex {
			flex: 1;
		}
	}
</style>
